<template>
  <div class="row">
    <div class="col-12">
      <template>
        <component
          :is="component"
          :pidInfo.sync="pidInfo"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'utilities-flow',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      pidInfo: {
        diagramClassCd: 'UTILS', // utilities
        title: 'utilities Flow',
      },
      component: null,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.component = () => import('@/pages/psi/pidMaster.vue');
      this.getList();
    },
    getList() {
    },
    reset() {
      Object.assign(this.$data.pidInfo, this.$options.data().pidInfo);
    },
  }
};
</script>